define([
  'modules/shop.cash-register-retail/views/productListWithSearch/layout',
  './list/layout',
], (View, ListView) => View.extend({
  renderList() {
    const region = this.getRegion('list');
    const view = new ListView({
      model: this.model,
      orderItemCollection: this.orderItemCollection,
    });
    region.show(view);
  },
}));
