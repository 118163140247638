define([
  'jquery',
  'underscore',
  'backbone',
  './collectionItem',
  'modules/shop.cash-register-retail/templates/shippingModal/overview/collectionStaticItem',
], (
  $, _, Backbone, CollectionItemView, Template,
) => CollectionItemView.extend({

  template: Template,

  onRender() {},

}));
