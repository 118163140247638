define([
  'jquery',
  'underscore',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/tables/addPopup.hbs',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/common/components/promisify',
  'modules/shop.cash-register-retail/components/toaster',

  'modules/shop.cash-register-retail/views/tables/overview/layout',

  'modules/shop.cash-register-retail/collections/loadable/tables',
  'modules/shop.cash-register-retail/collections/loadable/tableAreas',
], (
  $, _, PopupView, Template,
  CashRegisterApi, Promisify, Toaster,
  TableOverview,
  Tables, TableAreas,
) => PopupView.extend({

  template: Template,

  className: 'dialog dialog--table-add-popup',

  events: {
    'click [data-action="close"]': 'closeClicked',
  },

  regions: {
    tableOverview: '[data-region=table-overview]',
  },

  childEvents: {
    'item:added': 'close',
    'loader:start': 'loaderStart',
  },

  loaderStart(__, childDef) {
    const def = this.startLoader();
    childDef.always(def.resolve);
  },

  async renderTableOverview() {
    const def = this.startLoader();

    try {
      // Check if there are any updates
      await Promise.all([
        Tables.loadPromise({ reload: true }),
        TableAreas.loadPromise({ reload: true }),
      ]);

      def.resolve();
    } catch (err) {
      console.error(err);
      const message = Promisify.extractTryCatchErrorMessage(err);
      Toaster.error(message);
    }

    const region = this.getRegion('tableOverview');
    const view = new TableOverview({
      tableMode: TableOverview.TABLE_MODE_ADD,
      printProductionReceipts: this.printProductionReceipts,
    });
    region.show(view);
  },

  open({ printProductionReceipts = false }) {
    this.printProductionReceipts = printProductionReceipts;

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/tables/addPopup',
    });

    this.renderInFloatingRegion();

    this.openPopup();

    this.renderTableOverview();
  },

}));
