define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/tables/addButton',

  'modules/common/components/locale',
  'modules/common/components/promisify',
  'modules/shop.cash-register-retail/components/productionGroupPrint',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/admin/behaviors/loader',

  'modules/shop.cash-register-retail/collections/loadable/tables',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/shop.cash-register-retail/components/feature',
  'modules/shop.cash-register-retail/views/tables/renamePopup.js',
], (
  $, _, Backbone, Template,
  Locale, Promisify, ProductionGroupPrint, Toaster, Loader,
  Tables,
  CurrentOrderItem, Feature, RenamePopupView,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'button',

  className: 'btn btn-default btn-block designer-box rounded',

  behaviors: {
    Loader: {
      behaviorClass: Loader,
      name: 'table-add-button',
    },
  },

  initialize({ tableId, printProductionReceipts }) {
    this.printProductionReceipts = printProductionReceipts;

    this.isRenameFeatureEnabled = Feature.getFeatureActiveByAppName(
      Feature.APP_NAME_CORE_SHOP_MODULE_TABLE_RENAME_ON_POS,
    );
    const tableModel = Tables.get(tableId);
    if (tableModel) {
      this.tableModel = tableModel;
    } else {
      const message = Locale.translate('unknown_table_with_id_{table_id}', { table_id: tableId });
      console.error(message);
      Toaster.error(message);
      this.tableModel = new Backbone.Model();
    }
  },

  events: {
    click: 'viewClicked',
    touchend: 'viewClicked',
  },

  viewClicked: _.debounce(async function () {
    const def = this.loader.startLoader();
    try {
      const tableId = this.tableModel.get('id');

      // Ensure table order exists
      await this.tableModel.ensureTableOrder();

      // Ensure the table is up to date
      await this.tableModel.fetchPromise();

      if (this.isRenameFeatureEnabled) {
        const popup = new RenamePopupView({
          model: this.tableModel,
        });
        await popup.openPromise();
      }

      // Create order part
      const tableOrderPartObject = await this.tableModel.ensureTableOrderPart();

      // Print production receipts if needed
      if (this.printProductionReceipts) {
        // Print production
        await ProductionGroupPrint.printFromTableOrderPartProduction(tableId, tableOrderPartObject);
        // Increment print count
        await ProductionGroupPrint.incrementTableOrderPartPrintCount(tableOrderPartObject.id);
      } else {
        // Print announcement
        await ProductionGroupPrint.printFromTableOrderPartAnnouncement(tableId, tableOrderPartObject);
      }

      // Clear order items from order
      CurrentOrderItem.clear();

      // Show success
      const table = this.tableModel.get('name');
      const table_area = this.tableModel.get('table_area.name');
      const successText = Locale.translate('items_added_to_{table}_{table_area}', { table, table_area });
      Toaster.success(successText);

      // Done
      def.resolve();

      // Reset checkout
      Backbone.history.navigate('hospitality', { trigger: true });
    } catch (err) {
      console.error(err);
      def.reject(err);
      const message = Promisify.extractTryCatchErrorMessage(err);
      Toaster.error(message);
    }
  }, 250),

  serializeData() {
    return {
      tableName: this.tableModel.get('name'),
      tableAreaName: this.tableModel.get('table_area.name'),
    };
  },

}));
