define([
  'jquery',
  'underscore',
  'backbone',

  './collectionItem',
  './collectionEmpty',

  'modules/common/collections/DeepModelCollection',
], (
  $, _, Backbone,
  ItemView, EmptyView,
  DeepModelCollection,
) => Backbone.Marionette.CollectionView.extend({

  childView: ItemView,

  childViewOptions() {
    return {
      tableModel: this.tableModel,
      tableOrderModel: this.tableOrderModel,
    };
  },

  childEvents: {
    'loader:start': function (__, def) { this.triggerMethod('loader:start', def); },
    'row:delete': function (__, model) { this.triggerMethod('row:delete', model); },
  },

  emptyView: EmptyView,

  initialize({ tableModel, tableOrderModel }) {
    this.tableModel = tableModel;
    this.tableOrderModel = tableOrderModel;
    this.collection = new DeepModelCollection(tableOrderModel.get('table_order_parts') || []);
  },

}));
