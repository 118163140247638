define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/tabs/button',

  './listPopup/popup',
  './infoPopup/popup',
  './swapPopup',
  'modules/shop.cash-register-retail/views/popups/confirmPopup',
  'modules/shop.cash-register-retail/views/popups/inputPopup',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/toaster',

  'modules/shop.cash-register-retail/collections/tab',
], (
  $, _, Backbone, Template,
  ListPopup, InfoPopup, SwapPopup, ConfirmPopup, InputPopup,
  Locale, Toaster,
  TabCollection,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'button',

  className: 'btn btn-info btn-block designer-box rounded',

  regions: {
    popup: '[data-region="popup"]',
  },

  events: {
    click: 'renderListPopup',
  },

  renderListPopup() {
    const view = new ListPopup();
    view.open();
    view.on('tab:add', this.addTab, this);
    view.on('tab:merge-order-items', this.mergeOrderItems, this);
    view.on('tab:info', this.renderInfoPopup, this);
  },

  renderInfoPopup(model) {
    const def = new $.Deferred();
    const view = new InfoPopup({
      model,
    });
    view.open();
    view.on('tab:delete', this.deleteTab, this);
    view.on('tab:edit', this.editTab, this);
    view.on('tab:swap', this.renderSwapPopup, this);
    view.on('tab:checkout', this.checkoutTab, this);
    view.on('popup:closed', def.resolve);
    return def;
  },

  renderSwapPopup(model) {
    const view = new SwapPopup({
      model,
    });
    view.open();
    view.on('tab:swap', this.moveTabs, this);
  },

  addTab() {
    const popup = new InputPopup();
    popup.open(
      Locale.translate('add_a_new_tab_current_order_items_will_be_added_dot'),
    ).then((data) => {
      const name = data.input.trim();
      if (name === '') {
        Toaster.error(Locale.translate('tab_name_can_not_be_empty_dot'));
        setTimeout(() => { this.addTab(); }); // Timeout needed to make sure the other popup is fully gone
      } else if (TabCollection.findWhere({ name })) {
        Toaster.error(Locale.translate('tab_name_{0}_already_used_dot', name));
        setTimeout(() => { this.addTab(); }); // Timeout needed to make sure the other popup is fully gone
      } else {
        const model = TabCollection.addNew(name);
        Toaster.success(Locale.translate('created_new_tab_with_name_{0}', model.get('name')));
        model.pullFromOrder();
        Toaster.success(Locale.translate('products_added_to_tab_{0}', model.get('name')));
        this.renderInfoPopup(model);
      }
    });
  },

  mergeOrderItems(model) {
    model.pullFromOrder();
    Toaster.success(Locale.translate('products_added_to_the_tab_{0}', model.get('name')));
    this.renderInfoPopup(model);
  },

  deleteTab(model) {
    const view = new ConfirmPopup();
    view.open(
      Locale.translate('are_you_sure_you_want_to_delete_tab_{0}_question', model.get('name')),
      Locale.translate('this_action_will_also_delete_any_ordered_items_from_that_tab_dot'),
    ).then(() => {
      model.destroy();
    });
  },

  editTab(model) {
    const popup = new InputPopup();
    popup.open(
      Locale.translate('change_tab_for_{0}', model.get('name')),
    ).then((data) => {
      const name = data.input.trim();
      if (name === '') {
        Toaster.error(Locale.translate('tab_name_can_not_be_empty_dot'));
        setTimeout(() => { this.editTab(model); }); // Timeout needed to make sure the other popup is fully gone
      } else {
        // Check if the name is taken
        const nameTaken = TabCollection.filter((tabModel) =>
        // Chech if the name is the same and if the ids are different
						 tabModel.get('name') === name && tabModel.get('id') !== model.get('id')).length > 0;

        if (nameTaken) {
          Toaster.error(Locale.translate('tab_name_{0}_already_used_dot', name));
          setTimeout(() => { this.editTab(model); }); // Timeout needed to make sure the other popup is fully gone
        } else {
          model.updateName(name);
          Toaster.success(Locale.translate('tab_name_updated_to_{0}', model.get('name')));
          this.renderInfoPopup(model);
        }
      }
    });
  },

  moveTabs(fromTab, toTab) {
    const oldFromTotals = fromTab.getOrderTotals();
    const oldToTotals = toTab.getOrderTotals();
    toTab.mergeWithTab(fromTab);
    TabCollection.clearTab(fromTab.get('id'));

    const data = [
      fromTab.get('name'),
      oldFromTotals,
      toTab.get('name'),
      oldToTotals,
      toTab.getOrderTotals(),
    ];
    const text = Locale.translate('moved_products_from_tab_{0}_{1}_to_{2}_{3}_where_the_new_total_is_{4}', data);
    Toaster.success(text);
  },

  checkoutTab(model) {
    model.pushToOrder()
      .then(() => {
        const id = model.get('id');
        Backbone.history.navigate(`payment/tab/${id}`, { trigger: true });
      });
  },

}));
