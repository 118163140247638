define([
  'jquery',
  'underscore',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/tabs/infoPopup/popup.hbs',

  './timeline',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.common/components/commerceAuth',
], (
  $, _, PopupView, Template,
  TimelineView,
  CashRegisterApi, CommerceAuth,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--tab-information',

  childEvents: {
    'loader:def': 'handleLoader',
  },

  events: {
    'click [data-action="delete"]': 'deleteClicked',
    'click [data-action="swap"]': 'swapClicked',
    'click [data-action="checkout"]': 'checkoutClicked',
    'click [data-action="close"]': 'closeClicked',
    'click [data-action="edit"]': 'editClicked',
    'click @ui.printBtn': 'printAllTimelines',
  },

  ui: {
    printBtn: 'button[data-action="print-full"]',
  },

  handleLoader(__, loaderDef) {
    const def = this.startLoader();
    loaderDef.always(() => def.resolve());
  },

  printAllTimelines() {
    this.timelineView.printAllTimelines();
  },

  deleteClicked() {
    if (this.canDelete()) {
      this.close();
      this.triggerMethod('tab:delete', this.model);
    }
  },

  editClicked() {
    this.close();
    this.triggerMethod('tab:edit', this.model);
  },

  swapClicked() {
    this.close();
    this.triggerMethod('tab:swap', this.model);
  },

  checkoutClicked() {
    if (this.hasOrderItems()) {
      this.close();
      this.triggerMethod('tab:checkout', this.model);
    }
  },

  closeClicked() {
    this.close();
    this.triggerMethod('popup:closed', this.model);
  },

  hasOrderItems() {
    const orderItems = this.model.get('order_items') || [];
    return orderItems.length > 0;
  },

  regions: {
    timeline: '[data-region="timeline"]',
  },

  onRender() {
    this.renderTimeline();
  },

  renderTimeline() {
    const region = this.getRegion('timeline');
    const view = new TimelineView({
      model: this.model,
    });
    region.show(view);
    this.timelineView = view;
  },

  open(def) {
    def = this.renderInFloatingRegion(def);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/tabs/list/infoPopup/popup',
    });

    this.openPopup();

    return def;
  },

  canDelete() {
    return CommerceAuth.hasAdminAccess() || CommerceAuth.hasManagerAccess();
  },

  serializeData() {
    const data = this.model.toJSON();
    data.currency_iso3 = this.model.getCurrencyIso3();
    data.totals = this.model.getOrderTotals();
    data.hasOrderItems = this.hasOrderItems();
    data.canDelete = this.canDelete();
    return data;
  },

}));
