define([
  'jquery',
  'underscore',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/tables/renamePopup.hbs',
  'modules/upx/components/upx',

  'modules/admin/behaviors/loader',
  'modules/upx/behaviors/validation',
  'modules/upx/behaviors/modelBinder',
  'modules/upx/behaviors/memento',
  'modules/shop.cash-register-retail/models/keyboard',
  'modules/shop.cash-register-retail/collections/loadable/tables',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/toaster',
], (
  $, _, PopupView, Template, Upx,
  Loader, Validation, ModelBinder, Memento, Keyboard, Tables,
  Locale, Toaster,
) => PopupView.extend({

  template: Template,

  className: 'dialog dialog--input',

  ui: {
    confirm: '[data-action="confirm"]',
    close: '[data-action="close"]',
    input: '[data-ui="input"]',
    form: '[data-ui="form"]',
  },

  events: {
    'submit @ui.form': 'confirmClicked',

    'click @ui.close': 'closeClicked',
    'click @ui.confirm': 'confirmClicked',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
    Memento: {
      behaviorClass: Memento,
    },
    ModelBinder: {
      behaviorClass: ModelBinder,
      method: 'updateTable',
    },
    Validation: {
      behaviorClass: Validation,
      method: 'updateTable',
      rules: {
        name: {
          required: true,
        },
      },
    },
  },

  open() {
    const def = this.renderInFloatingRegion();

    this.startKeyboard();

    this.logOpenAction('modules/shop.cash-register-retail/views/tables/renamePopup');
    this.openPopup();

    setTimeout(() => {
      this.ui.input.focus();
      this.ui.input.click();
    }, 100);

    return def;
  },

  confirm() {
    const def = this.startLoader();

    if (this.model.isValid(true)) {
      if ('name' in this.model.changes()) {
        Upx.call('ShopModule', 'updateTable', {
          fields: {
            name: this.model.get('name'),
            __allow_when_used: true,
          },
          id: this.model.get('id'),
        }).then(
          () => {
            this.model.store();
            const table = this.model.get('name');
            const tableModel = Tables.get(this.model.get('id'));
            tableModel.set('name', table); // set the table in cached model

            const successText = Locale.translate('table_was_renamed_to_{table}', { table });
            Toaster.success(successText);

            def.resolve();
          },
          ({ error }) => {
            Toaster.error(error);
            def.reject();
          },
        );
      } else {
        def.resolve();
      }
    } else {
      def.reject();
    }

    def.then(
      () => PopupView.prototype.confirm.call(this),
    );
  },

  close() {
    this.closeDef.resolve(); // cancel is also ok
    this.stopKeyboard();
    PopupView.prototype.close.call(this);
  },

  startKeyboard() {
    Keyboard.setViewWithMode(this.cid, Keyboard.MODE_INPUT_POPUP);
  },

  stopKeyboard() {
    Keyboard.setNeutralMode();
  },
}));
