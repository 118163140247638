define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
], (
  $, _, Backbone,
  ShopConfiguration,
) => Backbone.Marionette.LayoutView.extend({

  tagName: 'div',

  className: 'btn designer-box rounded table-item',

  serializeData() {
    const tableOrderValue = this.model.get('table_order.value_wt') || 0;
    return _.extend({}, this.model.toJSON(), {
      currency_iso3: ShopConfiguration.getCurrencyIso3(),
      availabilityClass: tableOrderValue > 0 ? 'text-success' : '',
    });
  },

}));
