define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/views/success/summary',
  'modules/shop.cash-register-retail/templates/successScreens/order/summary.hbs',
], (
  $, _, Backbone, SummaryView, Template,
) => SummaryView.extend({
  template: Template,
}));
