define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/tables/informationPopup/collectionItem.hbs',

  './collectionItemRow',

  'modules/common/collections/DeepModelCollection',

  'modules/common/components/moment',
  'modules/common/components/promisify',
  'modules/shop.cash-register-retail/components/productionGroupPrint',
  'modules/shop.cash-register-retail/components/toaster',
], (
  $, _, Backbone, Template,
  RowView,
  DeepModelCollection,
  Moment, Promisify, ProductionGroupPrint, Toaster,
) => Backbone.Marionette.CompositeView.extend({

  className: 'table-order-item',

  template: Template,

  childView: RowView,

  childViewContainer: 'tbody',

  childEvents: {
    'row:delete': function (__, model) { this.triggerMethod('row:delete', model); },
  },

  events: {
    'click [data-action=print]': 'printClicked',
  },

  getPrintData() {
    const data = this.model.toJSON();

    delete data.table_order_items;
    data.table_order_items = this.collection.chain()
      .filter((model) => this.filter(model))
      .map((model) => model.toJSON())
      .value();

    return data;
  },

  printClicked: _.debounce(async function () {
    const def = new $.Deferred();
    this.triggerMethod('loader:start', def);
    try {
      const { id, times_printed_to_production: timesPrinted } = this.model.toJSON();

      // Print the order part
      if (timesPrinted === 0) {
        // First print
        await ProductionGroupPrint.printFromTableOrderPartProduction(
          this.tableModel.get('id'),
          this.getPrintData(),
        );
      } else {
        // Duplicate print
        await ProductionGroupPrint.printFromTableOrderPartDuplicate(
          this.tableModel.get('id'),
          this.getPrintData(),
        );
      }

      // Increment print count
      const newTimesPrinted = await ProductionGroupPrint.incrementTableOrderPartPrintCount(id);

      // Update the send times
      this.model.set('times_printed_to_production', newTimesPrinted);

      // Done
      def.resolve();

      // Update the view
      this.render();
    } catch (err) {
      def.reject();
      console.error(err);
      const message = Promisify.extractTryCatchErrorMessage(err);
      Toaster.error(message);
    }
  }, 150),

  initialize({ tableModel, tableOrderModel }) {
    this.tableModel = tableModel;
    this.tableOrderModel = tableOrderModel;
    this.collection = new DeepModelCollection(tableOrderModel.get('table_order_items'));
  },

  filter(model) {
    return this.model.get('id') === model.get('table_order_part_id');
  },

  getTextClass() {
    const { needs_print_to_production, times_printed_to_production } = this.model.toJSON();

    if (!needs_print_to_production) {
      return 'text-muted';
    }
    if (times_printed_to_production === 0) {
      return '';
    } if (times_printed_to_production > 1) {
      return 'text-danger';
    }
    // Already printed
    return 'text-info';
  },

  serializeData() {
    const date = new Moment(this.model.get('date_created'));
    return _.extend({}, this.model.toJSON(), {
      time: date.format('HH:mm'),
      date: date.format('LL'),
      textClass: this.getTextClass(),
    });
  },

}));
