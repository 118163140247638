define([
  './item',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/templates/repairs/questions/choiceItem.hbs',
], (ItemView, Locale, Template) => ItemView.extend({
  template: Template,
  getValue() {
    return {
      question_id: this.model.get('id'),
      question_text: this.model.get('question'),
      answer_text: this.ui.answerField.find('option:selected').text(),
      question_option_id: this.ui.answerField.val(),
    };
  },
}));
