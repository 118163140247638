define([
  'jquery',
  'backbone',
  './freeInputItem',
  './choiceItem',
  './canvasItem',
], ($, Backbone, FreeInputItemView, ChoiceItemView, CanvasItemView) => Backbone.Marionette.CollectionView.extend({
  getChildView(model) {
    switch (model.get('question_type')) {
      case 'free_input':
        return FreeInputItemView;
      case 'choice':
        return ChoiceItemView;
      case 'canvas':
        return CanvasItemView;
      default:
        return null;
    }
  },

  getValues() {
    const def = $.Deferred();

    if (this.collection.length === 0) {
      // no question, no answer
      def.resolve([]);
    }

    const childDefs = [];

    this.children.forEach((itemView) => {
      if (itemView.hasAnswer()) {
        childDefs.push(itemView.getValue());
      }
    });

    $.when.apply(this, childDefs).then((...answers) => {
      def.resolve(answers);
    }, def.reject);

    return def.promise();
  },
}));
