define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/tables/informationPopup/collectionEmpty.hbs',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

}));
