define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/repairs/details/layout.hbs',
  'modules/upx/behaviors/modelBinder',

  'modules/shop.cash-register-retail/views/products/totals',
  './productListWithSearch/layout',
  'modules/shop.cash-register-retail/views/products/list/layout',
  'modules/shop.cash-register-retail/collections/orderItem',
  'modules/shop.cash-register-retail/models/orderItem',

  'modules/shop.cash-register-retail/views/customers/selection/swappable',
  'modules/shop.cash-register-retail/models/customer',
  'modules/shop.cash-register-retail/components/repair',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
  'modules/shop.cash-register-retail/components/printing',

  'modules/shop.cash-register-retail/views/keypads/main',
  'modules/common/components/historyBreadcrumb',
  'modules/shop.cash-register-retail/views/popups/confirmPopup',
  'modules/shop.cash-register-retail/views/popups/messagePopup',
  'modules/shop.cash-register-retail/views/popups/repairLogsPopup',
  'modules/common/components/locale',
  'toastr',
  'modules/admin/behaviors/loader',

  'modules/shop.cash-register-retail/views/popups/repairMoreDetailsPopup',
  'upx.modules/ShopModule/collections/RepairQuestionAnswer',
], (
  $, _, Backbone, Template, ModelBinder,
  ProductTotalsView, ProductListAndSearchView, ProductListView, OrderItemCollection, OrderItemModel,
  CustomerSelectionView, CustomerModel, RepairComponent, ReceiptPrinterModel, PrintingComponent,
  KeypadPercentageView, HistoryBreadcrumb, ConfirmPopup, MessagePopup, RepairLogsPopup, Locale, Toastr, Loader,
  RepairMoreDetailsPopupView, RepairQuestionAnswerCollection,
) => Backbone.Marionette.LayoutView.extend({

  className: 'repair-details',

  template: Template,

  regions: {
    customer: '[data-region="customer"]',
    keypad: '[data-region="keypad"]',
    productListAndSearch: '[data-region="product-list-and-search"]',
    'product-totals': '[data-region="product-totals"]',
  },

  behaviors: {
    ModelBinder: {
      behaviorClass: ModelBinder,
      method: 'updateRepair',
      changeTriggers: {
        '': 'change focusout keyup',
      },
    },
    Loader: {
      behaviorClass: Loader,
    },
  },

  ui: {
    printReceiptButton: '[data-ui="print-receipt"]',
    finishButton: '[data-ui="finish"]',
    backButton: '[data-ui="back"]',
    logsButton: '[data-ui="logs"]',
    buttonContainer: '[data-ui="checkoutButtons"]',
    moreDetailsButton: '[data-ui="moreDetails"]',
  },

  events: {
    'click @ui.backButton': 'backClicked',
    'click @ui.printReceiptButton': 'printReceiptClicked',
    'click @ui.logsButton': 'logsClicked',
    'click @ui.finishButton': 'finishClicked',
    'click @ui.moreDetailsButton': 'moreDetailsClicked',
  },

  modelEvents: {
    'change:is_anonymous': 'onSaveDebounced',
    'change:relation_data_id': 'onSaveDebounced',
    'change:repair_items': 'onSaveDebounced',
  },

  initialize() {
    this.customerModel = new CustomerModel();
    this.itemsCollection = this.buildItemsCollection();
    this.isSaving = false;
    this.removeProtection = false;
  },

  moreDetailsClicked: _.debounce(function () {
    const moreDetailsPopupView = new RepairMoreDetailsPopupView({
      repairId: this.model.get('id'),
    });
    const number = this.model.get('number');
    moreDetailsPopupView.open(
      Locale.translate('repair_{number}', { number }),
    );
  }, 100),

  isEditable() {
    return !this.model.get('is_finalized') && !this.model.get('is_canceled');
  },

  getItemsCollectionClass() {
    const saveItems = () => this.saveItems();
    return OrderItemCollection.extend({
      model: OrderItemModel.extend({
        sync() {
          saveItems();
        },
      }),
    });
  },

  pageProtector() {
    if (this.isSaving && !this.removeProtection) {
      const view = new ConfirmPopup();
      view.open(
        Locale.translate('not_all_data_has_been_saved'),
        Locale.translate('you_will_your_changes_to_repair_if_you_leave_now_please_wait_do_you_want_to_remove_the_page_protection_anyway'),
      ).then(
        () => {
          this.removeProtection = true;
        },
      );

      return true;
    }
    return null;
  },

  backClicked: _.debounce(() => {
    HistoryBreadcrumb.goBack();
  }, 100),

  printReceiptClicked: _.debounce(function printReceipt() {
    const def = this.loader.startLoader('loader-print');
    const repairQuestionAnswerCollection = new RepairQuestionAnswerCollection();
    const params = {
      start: 0,
      limit: 100,
      sort: [{
        name: 'repair_question/sequence_order',
        dir: 'asc',
      }, {
        name: 'question_text',
        dir: 'asc',
      }],
      filters: [{
        name: 'repair_id__=',
        val: this.model.get('id'),
      }],
    };

    repairQuestionAnswerCollection.fetchAll({ params }).then(() => {
      this.model.set('repair_question_answers', repairQuestionAnswerCollection.toJSON());
      PrintingComponent.printRepairReceipt(this.model)
        .then(def.resolve, (response) => {
          def.reject();

          if (response && 'error' in response) {
            const view = new MessagePopup();
            view.open(
              response.error,
            );
          }
        });
    });
  }, 100),

  logsClicked: _.debounce(function () {
    const logsView = new RepairLogsPopup({
      repair_id: this.model.get('id'),
    });
    const number = this.model.get('number');
    logsView.open(
      Locale.translate('repair_logs_for_{number}', { number }),
    );
  }, 100),

  finishClicked: _.debounce(function () {
    if (this.itemsCollection.length > 0) {
      RepairComponent.pushRepairToOrder(
        this.model.get('id'),
        this.customerModel,
        this.itemsCollection,
      );
    } else {
      const messageView = new MessagePopup();
      messageView.open(Locale.translate('add_products_if_you_want_to_finalize_the_repair'));
    }
  }, 100),

  buildItemsCollection() {
    const collection = new (this.getItemsCollectionClass())();
    if (this.model.get('repair_items')) {
      this.model.get('repair_items').forEach((repairItem) => {
        const model = new Backbone.DeepModel(repairItem);
        const subitems = [];
        if (model.get('subitems')) {
          $.each(model.get('subitems'), (i, subitem) => {
            const subitemModel = new Backbone.DeepModel(subitem);
            subitemModel.set('to_be_shipped_quantity', subitemModel.get('quantity'));
            const data = collection.getOrderItemDataFromModel({
              orderItemModel: subitemModel,
            });
            subitems.push(
              collection.newOrderItemModel(data).toJSON(),
            );
          });
        }
        model.set('sub_items', subitems);
        model.set('ppu_wt', model.get('before_discount_ppu_wt') || model.get('ppu_wt'));
        model.set('to_be_shipped_quantity', model.get('quantity'));
        const newRow = collection.getOrderItemDataFromModel({
          orderItemModel: model,
        });
        newRow.id = repairItem.id;
        newRow.is_reserved = repairItem.is_reserved || false;
        const orderModel = collection.addOrderItemData(newRow);
        if (repairItem.before_discount_ppu_wt
            && repairItem.before_discount_ppu_wt > repairItem.ppu_wt) {
          orderModel.setDiscountPpuWt(
            (repairItem.before_discount_ppu_wt - repairItem.ppu_wt).toFixed(2),
          );
        }
      });
    }
    return collection;
  },

  saveItems() {
    if (this.itemsCollection) {
      // if this.itemsCollection is empty mean it's initializing the collection

      const loader = this.itemsCollection.where({ type: this.itemsCollection.TYPE_LOADER });
      if (loader.length === 0) {
        // nothing loading we can save
        const items = [];
        this.itemsCollection.each((model) => {
          items.push(
            this.translateOrderItemToRepairItem(model),
          );
        });
        this.model.unset('repair_items'); // needed otherwise it will be merged
        this.model.set(
          'repair_items',
          items,
        );
      }
    }
  },

  translateOrderItemToRepairItem(orderItemModel) {
    const copyKeys = [
      'ppu_wt', 'quantity', 'sku', 'name',
      'description', 'shop_product_id',
      'before_discount_ppu_wt', 'is_reserved',
    ];
    const repairItem = {};
    copyKeys.forEach((key) => {
      repairItem[key] = orderItemModel.get(key) || null;
    });
    const discount_ppu_wt = orderItemModel.get('discount_ppu_wt');
    if (!!discount_ppu_wt && discount_ppu_wt !== '0.00') {
      repairItem.ppu_wt = parseFloat(repairItem.before_discount_ppu_wt)
          - parseFloat(discount_ppu_wt);
    } else {
      repairItem.before_discount_ppu_wt = repairItem.ppu_wt;
    }
    if (orderItemModel.has('sub_items')) {
      const subitems = [];
      $.each(orderItemModel.get('sub_items'), (i, subitem) => {
        const data = this.translateOrderItemToRepairItem(new Backbone.DeepModel(subitem));
        subitems.push(data);
      });
      repairItem.subitems = subitems;
    }
    return repairItem;
  },

  onRender() {
    this.renderCustomer();
    if (this.isEditable()) {
      this.renderProductListAndSearch();
    } else {
      this.renderReadonlyProductList();
    }
    this.renderProductTotals();
    this.renderKeypad();

    this.listenTo(this.itemsCollection, 'remove', () => {
      this.saveItems();
    });
    this.listenTo(this.itemsCollection, 'reset', () => {
      this.saveItems();
    });

    window.onbeforeunload = () => this.pageProtector();
    this.loader.addNamedLoader(this.ui.buttonContainer, 'buttons');
  },

  renderProductTotals() {
    const region = this.getRegion('product-totals');
    const view = new ProductTotalsView({
      isEditable: true,
      orderItemCollection: this.itemsCollection,
    });
    region.show(view);
  },

  renderKeypad() {
    const region = this.getRegion('keypad');
    const view = new KeypadPercentageView();
    region.show(view);
  },

  renderCustomer() {
    const region = this.getRegion('customer');
    if (this.isEditable()) {
      if (!this.model.get('is_anonymous')) {
        this.customerModel.selectByRelationDataId(this.model.get('relation_data_id'));
      }
      const view = new CustomerSelectionView({
        model: this.customerModel,
        showInvoicePopup: false,
        showPermanentDiscountPopup: false,
      });
      region.show(view);
      this.listenTo(this.customerModel, 'change:id', _.debounce(() => this.onCustomerChange(), 5));
    } else if (!this.model.get('is_anonymous')) {
      this.customerModel.selectByRelationDataId(this.model.get('relation_data_id'));
      const view = new CustomerSelectionView({
        model: this.customerModel,
        showInvoicePopup: false,
        showPermanentDiscountPopup: false,
        allowChange: false,
      });
      region.show(view);
    }
  },

  renderProductListAndSearch() {
    const region = this.getRegion('productListAndSearch');
    const view = new ProductListAndSearchView({
      checkoutBtn: this.ui.finishButton,
      orderItemCollection: this.itemsCollection,
    });
    region.show(view);
  },

  renderReadonlyProductList() {
    const region = this.getRegion('productListAndSearch');
    const view = new ProductListView({
      collection: this.itemsCollection,
      columns: [
        'quantity',
        'description',
        'ppu',
        'discount',
        'price',
      ],
      editable_columns: {},
    });
    region.show(view);
  },

  onCustomerChange() {
    if (this.customerModel.has('id')) {
      this.model.set({
        is_anonymous: false,
        relation_data_id: this.customerModel.get('id'),
      });
    } else {
      this.model.set({
        is_anonymous: true,
      });
    }
  },

  serializeData() {
    const data = this.model.toJSON();

    return $.extend(
      {
        statusTitle: RepairComponent.getStatusTitle(this.model),
        statusClass: RepairComponent.getStatusClass(this.model),
        isEditable: this.isEditable(),
        has_receipt_printer: ReceiptPrinterModel.isWantedType(),
      },
      data,
    );
  },
  onSaveDebounced: _.debounce(function () {
    this.onSave();
  }, 200),

  onSave() {
    if (this.model.isValid(true)) {
      this.isSaving = true;
      const loaderDef = new $.Deferred();
      this.ui.buttonContainer.find('.loader-wrapper').show();
      loaderDef.always(() => {
        this.isSaving = false;
        this.ui.buttonContainer.find('.loader-wrapper').hide();
      });
      const extra = {
        repair_items__do_not_change: !('repair_items' in this.model.changed),
      };
      this.model.save(this.model.changed, { parameters: { fields: extra } }).then(
        () => {
          loaderDef.resolve();
        },
        (error) => {
          Toastr.error(error.message);
          loaderDef.reject();
        },
      );
    } else {
      Toastr.error('Invalid data');
    }
  },
}));
