define([
  'jquery',
  'underscore',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/tabs/listPopup/popup',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/common/components/locale',

  './collection',
  'modules/shop.cash-register-retail/views/inputs/stringInput',
], (
  $, _, PopupView, Template,
  CashRegisterApi, Locale,
  CollectionView, StringInput,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--tab-list',

  events: {
    'click [data-action="close"]': 'closeClicked',
    'click [data-action="add"]': 'tabAdd',
    'click [data-action="reset"]': 'resetClicked',
  },

  regions: {
    list: '[data-region=list]',
    search: '[data-region=search]',
  },

  childEvents: {
    'tab:info': 'tabInfo',
    'tab:merge-order-items': 'tabMergeOrderItems',
  },

  tabInfo(__, model) {
    this.triggerMethod('tab:info', model);
  },

  tabMergeOrderItems(__, model) {
    this.triggerMethod('tab:merge-order-items', model);
  },

  resetClicked() {
    this.renderList();
    this.renderSearch();
  },

  tabAdd() {
    this.triggerMethod('tab:add');
  },

  renderList(searchQuery) {
    const region = this.getRegion('list');
    const view = new CollectionView({
      searchQuery,
    });
    region.show(view);
  },

  renderSearch() {
    const region = this.getRegion('search');
    const view = new StringInput({
      placeholder: Locale.translate('search_a_tab'),
    });
    region.show(view);
    view.on('value:search', (value) => {
      this.renderList(value);
    });
  },

  onRender() {
    this.renderList();
    this.renderSearch();
  },

  open(def) {
    def = this.renderInFloatingRegion(def);
    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/tabs/listPopup/popup',
    });

    this.openPopup();

    return def;
  },

}));
