define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/shippingModal/partialPopup',

  './overview/collection',

  'modules/shop.cash-register-retail/collections/orderItem',
  'modules/shop.cash-register-retail/collections/currentOrderItem',

  'modules/shop.cash-register-retail/components/toaster',
  'modules/common/components/locale',
  'modules/common/components/moment',
  'modules/shop.cash-register-retail/components/datepickk',
  'modules/shop.cash-register-retail/components/shipmentMethodType',

  'modules/admin/behaviors/loader',
  'modules/shop.cash-register-retail/behaviors/shippingMethodInput',
], (
  $, _, Backbone, PopupView, Template,
  OverviewView,
  OrderItemCollection, CurrentOrderItemCollection,
  Toaster, Locale, Moment, Datepickk, ShipmentMethodType,
  Loader, ShippingMethodInput,
) => PopupView.extend({

  template: Template,

  className: 'dialog dialog--handout',

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
    ShippingMethodInput: {
      behaviorClass: ShippingMethodInput,
    },
  },

  ui: {
    closeButton: '[data-ui=close]',
    backButton: '[data-ui=back]',
    saveButton: '[data-ui=save]',
    content: '[data-ui=content]',
    method: '[data-ui=method]',
    dayPicker: '[data-ui="day-picker"]',
    pickupDate: '[data-ui="pickup-date"]',
  },

  regions: {
    content: '@ui.content',
  },

  events: {
    // Close
    'click @ui.closeButton': 'closeClicked',
    'touchend @ui.closeButton': 'closeClicked',
    // Save
    'click @ui.backButton': 'closeClicked',
    // Save
    'click @ui.saveButton': 'saveClicked',
    'click @ui.dayPicker': 'showDayPicker',
    // method
    'change @ui.method': 'methodChanged',
  },

  initialize({ orderModel, shippingMethodCollection, orderItemCollection = null }) {
    this.shippingMethodCollection = shippingMethodCollection;
    this.orderModel = orderModel;
    this.orderItemCollection = orderItemCollection || CurrentOrderItemCollection;
    this.collection = this.getCollection();

    if (this.orderModel.has('pickup_date') && this.orderModel.get('pickup_date')) {
      this.currentPickupDate = this.orderModel.get('pickup_date');
    } else {
      this.currentPickupDate = null;
    }

    this.shippingMethodId = null;
    const shippingMethodModel = this.shippingMethodCollection.first();
    if (this.orderModel.has('shipping_method_id')) {
      this.shippingMethodId = this.orderModel.get('shipping_method_id');
    } else if (shippingMethodModel) {
      this.shippingMethodId = shippingMethodModel.get('id');
    }
  },

  open() {
    this.logOpenAction(
      'modules/shop.cash-register-retail/views/shippingModal/partialPopup',
    );

    const def = this.renderInFloatingRegion();

    this.openPopup();
    return def;
  },

  onRender() {
    this.renderContent();
  },

  getCollection() {
    const collection = new OrderItemCollection();

    this.orderItemCollection.each((model) => {
      if (model.get('unfulfilled_quantity') > 0) {
        // only positive can set to partial
        const orderModel = collection.addProductByOrderItemModel({
          orderItemModel: model,
          saveModel: false,
          forceNew: true,
        });
        let quantity = orderModel.get('unfulfilled_quantity');
        if (model.has('to_be_shipped_quantity')) {
          quantity = model.get('to_be_shipped_quantity');
        }
        let serial_nos = [];
        if (model.has('to_be_shipped_serial_nos')) {
          serial_nos = model.get('to_be_shipped_serial_nos');
        } else if (model.has('serial_nos')) {
          serial_nos = model.get('serial_nos');
        }
        orderModel.unset('to_be_shipped_serial_nos', { silent: true });
        orderModel.set({
          to_be_shipped_quantity: quantity,
          to_be_shipped_serial_nos: serial_nos,
        });
      }
    });

    return collection;
  },

  renderContent() {
    const { collection } = this;
    // Setup the view
    const view = new OverviewView({
      mode: 'edit',
      showSku: false,
      collection,
    });
    // Render view in region
    const region = this.getRegion('content');
    region.show(view);
  },

  methodChanged(ev) {
    const el = ev.currentTarget;
    this.shippingMethodId = parseInt(el.value, 10);
    this.render();
  },

  showDayPicker() {
    const datepicker = new Datepickk();

    datepicker.lang = Locale.getLocale();
    datepicker.button = Locale.translate('ok');
    datepicker.today = true;
    datepicker.maxSelections = 1;
    datepicker.minDate = new Moment();
    datepicker.onConfirm = () => {
      const [date] = datepicker.selectedDates;

      this.setPickupDate(date);
    };

    datepicker.show();
  },

  setPickupDate(date) {
    const moment = new Moment(date);
    this.orderModel.set('pickup_date', moment.format('YYYY-MM-DD'));
    this.currentPickupDate = moment.format('YYYY-MM-DD');
    this.ui.pickupDate.val(moment.format('LL'));
  },

  saveClicked() {
    const def = this.loader.startLoader();

    const shippingMethodModel = this.shippingMethodCollection.get(this.shippingMethodId).clone();

    if (this.currentPickupDate) {
      shippingMethodModel.set('pickup_date', this.currentPickupDate);
    }

    const clonedOrderModel = this.orderModel.clone();

    clonedOrderModel.addShippingMethodModel(shippingMethodModel);

    if (clonedOrderModel.has('id')) {
      clonedOrderModel.unset('shipping_method_id', { silent: true });
    }

    this.orderModel.set('shipping_method_id', this.shippingMethodId);

    this.collection.each((model) => {
      // Extract data
      const {
        // eslint-disable-next-line camelcase
        order_item_id, unfulfilled_quantity, to_be_shipped_quantity,
        to_be_shipped_serial_nos,
      } = model.toJSON();
      // Apply data
      const currentOrderItem = this.orderItemCollection.get(order_item_id);
      currentOrderItem.unset('to_be_shipped_serial_nos', { silent: true });
      currentOrderItem.set({
        unfulfilled_quantity,
        to_be_shipped_quantity,
        to_be_shipped_serial_nos,
      });
    });

    const preparedModel = clonedOrderModel.getPreparedModel();

    preparedModel.save().then(() => {
      def.resolve();
      this.close();
    }, def.reject);

    return def;
  },

  isPickupDateVisible() {
    const shippingMethodModel = this.shippingMethodCollection.get(this.shippingMethodId);
    return shippingMethodModel.get('shipping_type.alias') === ShipmentMethodType.TYPE_PICK_AT_STORE;
  },

  serializeData() {
    const collection = this.shippingMethodCollection;
    const model = this.shippingMethodCollection.first();

    let formattedPickupDate = null;
    if (this.currentPickupDate) {
      formattedPickupDate = Moment(this.currentPickupDate).locale(Locale.getLocale()).format('LL');
    }

    const hasMultipleMethods = collection.length > 1;
    let shippingMethodIcon = null;
    if (!hasMultipleMethods) {
      shippingMethodIcon = ShipmentMethodType.getIconForType(model.get('shipping_type.alias'));
    }

    return {
      shippingMethodId: this.shippingMethodId,
      methods: collection.toJSON(),
      methodName: model ? model.get('name') : null,
      hasMultipleMethods,
      isPickupDateVisible: this.isPickupDateVisible(),
      pickupDate: formattedPickupDate,
      shippingMethodIcon,
    };
  },

}));
