define([
  'jquery',
  'backbone',
], ($, Backbone) => Backbone.Marionette.ItemView.extend({

  ui: {
    answerField: '#answer',
  },

  hasAnswer() {
    return !!this.ui.answerField.val();
  },

  getValue() {
    return {
      question_id: this.model.get('id'),
      question_text: this.model.get('question'),
      answer_text: this.ui.answerField.val(),
    };
  },
}));
