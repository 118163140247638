define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/shippingModal/handoutPopup',

  './overview/collection',

  'modules/shop.cash-register-retail/collections/orderItem',

  'upx.modules/ShopModule/models/OrderShipment',
  'upx.modules/ShippingModule/models/Shipment',
  'modules/shop.cash-register-retail/collections/upx/ShipmentStatusType.js',

  'modules/shop.cash-register-retail/components/toaster',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/printing',
  'modules/shop.cash-register-retail/components/shipmentMethodType',

  'modules/shop.cash-register-retail/models/settings/receiptPrinter',

  'modules/shop.cash-register-retail/behaviors/shippingMethodInput',
], (
  $, _, Backbone, PopupView, Template,
  TableView,
  OrderItemCollection,
  OrderShipmentModel, ShipmentModel, ShipmentStatusTypeCollection,
  Toaster, Locale, PrintingComponent, ShipmentMethodType,
  ReceiptPrinter,
  ShippingMethodInput,
) => PopupView.extend({

  behaviors: {
    ShippingMethodInput: {
      behaviorClass: ShippingMethodInput,
    },
  },

  template: Template,

  className() {
    // this.collection cannot be used because it's run before initialize
    const className = this.getCollection().length > 0 ? 'dialog--handout' : 'dialog--message';
    return `dialog ${className}`;
  },

  ui: {
    closeButton: '[data-ui=close]',
    confirmButton: '[data-ui=confirm]',
    backButton: '[data-ui=back]',
    deliverButton: '[data-ui=deliver]',
    prepareButton: '[data-ui=prepare]',
    content: '[data-ui="content"]',
    contentEmpty: '[data-ui="content-empty"]',
    printCheckbox: '[data-ui="print-checkbox"]',
    method: '[data-ui=method]',
  },

  regions: {
    content: '@ui.content',
  },

  events: {
    'click @ui.closeButton': 'closeClicked',
    'click @ui.confirmButton': 'confirmClicked',
    'click @ui.backButton': 'backClicked',
    'click @ui.deliverButton': 'deliverClicked',
    'click @ui.prepareButton': 'prepareClicked',
    'change @ui.method': 'methodChanged',
  },

  collectionEvents: {
    all: 'handoutCheck',
  },

  MODE_EDIT: 'edit',
  MODE_CONFIRM: 'confirm',

  initialize({ model, shippingMethodCollection }) {
    this.model = model;
    this.collection = this.getCollection();
    this.shippingMethodCollection = shippingMethodCollection;
    this.mode = this.MODE_EDIT;

    this.setupMethod();
  },

  setupMethod() {
    if (this.model.has('shipping_method_id')) {
      this.shippingMethodId = this.model.get('shipping_method_id');
    } else {
      const model = this.shippingMethodCollection.first();
      if (model) {
        this.shippingMethodId = model.get('id');
      }
    }
  },

  methodChanged(ev) {
    const el = ev.currentTarget;
    this.shippingMethodId = el.value;
  },

  onRender() {
    this.renderContent();
  },

  open() {
    this.logOpenAction(
      'modules/shop.cash-register-retail/views/shippingModal/handoutPopup',
    );

    this.renderInFloatingRegion();

    this.openPopup();
  },

  getCollection() {
    const collection = new OrderItemCollection();

    this.model.get('order_items')
      .forEach((orderItem) => {
        if (orderItem.is_product && orderItem.unfulfilled_quantity > 0) {
          const model = collection.addProductByOrderItem({
            orderItem,
            saveModel: false,
            useFlatProductName: false,
            forceNew: true,
          });
          model.set(
            this.model.shipmentItemSerialsKey,
            orderItem[this.model.shipmentItemSerialsKey] || [],
          );
        }
      });

    collection.each((model) => {
      const quantity = model.get('unfulfilled_quantity');
      const shipped_serial_nos = model.get(this.model.shipmentItemSerialsKey);
      const serial_nos = [...(model.get('serial_nos') || [])].filter(
        (x) => !shipped_serial_nos.includes(x),
      );
      model.set({
        to_be_shipped_quantity: quantity,
        to_be_shipped_serial_nos: serial_nos,
      });
    });

    return collection;
  },

  renderContent() {
    const { mode, collection } = this;

    if (collection.length > 0) {
      // Setup the view
      const view = new TableView({
        mode,
        collection,
      });
      // Render view in region
      const region = this.getRegion('content');
      region.show(view);
    }
  },

  confirmClicked() {
    this.mode = this.MODE_CONFIRM;
    this.render();
    this.renderContent();
  },

  backClicked() {
    this.mode = this.MODE_EDIT;
    this.render();
    this.renderContent();
  },

  deliverClicked() {
    return this.deliverShipment(false);
  },
  prepareClicked() {
    return this.deliverShipment(true);
  },

  async deliverShipment(onlyPrepare) {
    const statusTypeId = onlyPrepare
      ? ShipmentStatusTypeCollection.getWaitingForCustomerPickupId()
      : ShipmentStatusTypeCollection.getDeliveredId();
    const def = this.startLoader();

    const printConfirm = this.ui.printCheckbox && this.ui.printCheckbox.is(':checked');
    let shipmentId = null;
    try {
      // Create shipment and delivered status type id
      shipmentId = await this.createShipment();

      // Mark shipment as delivered
      await this.updateShipment(shipmentId, statusTypeId);

      if (printConfirm) {
        await this.printConfirmation(shipmentId, onlyPrepare);
      }
      // Notify user
      Toaster.success(Locale.translate('marked_selected_items_as_collected'));

      this.model.trigger('list:reload');

      // Close popup;
      this.close();
    } catch (err) {
      Toaster.error(err.error);
      if (shipmentId) {
        // On error we are removing the shipment.
        this.deleteShipment(shipmentId);
      }
    }

    def.resolve();
  },

  printConfirmation(shipmentId, onlyPrepare) {
    return new Promise((resolve, reject) => {
      this.model.loadShipmentItems().then(
        () => {
          const def = onlyPrepare
            ? PrintingComponent.printOrderShipmentContents(this.model, shipmentId)
            : PrintingComponent.printOrderShipmentDelivery(this.model, shipmentId);

          def.then(
            resolve,
            (err) => {
              Toaster.error(err.error);
              reject(err);
            },
          );
        },
        reject,
      );
    });
  },

  createShipment() {
    return new Promise((resolve, reject) => {
      const orderShippingModel = new OrderShipmentModel({
        shipping_method_id: this.shippingMethodId,
        force_negative_stock_if_missing: true,
        order_id: this.model.get('id'),
        order_items: this.getOrderShipmentItems(),
      });

      orderShippingModel.save()
        .then(resolve, reject);
    });
  },

  getOrderShipmentItems() {
    const orderItems = [];
    // Extract collection items
    this.collection.each((model) => {
      const quantity = model.get('to_be_shipped_quantity');
      const id = model.get('order_item_id');
      const serial_nos = model.get('to_be_shipped_serial_nos');
      // Add item if the shipped quantity is higher then 0
      if (quantity > 0) {
        orderItems.push({ id, quantity, serial_nos });
      }

      const addOns = model.get('sub_items') || null;

      if (addOns && addOns.length > 0) {
        addOns.forEach((addOn) => {
          orderItems.push({ id: addOn.id, quantity });
        });
      }
    });

    return orderItems;
  },

  updateShipment(shipmentId, deliveredStatusTypeId) {
    return new Promise((resolve, reject) => {
      const model = new ShipmentModel({
        notify: false,
        shipment_status_type_id: deliveredStatusTypeId,
        id: shipmentId,
      });

      model.save()
        .then(resolve, reject);
    });
  },

  deleteShipment(shipmentId) {
    const model = new ShipmentModel({
      id: shipmentId,
    });

    model.destroy()
      .fail((resp) => Toaster.error(resp.error));
  },

  handoutCheck() {
    // Check if there are any items that are marked to be shipped
    let hasAnyToShip = this.collection
      .filter((model) => model.get('to_be_shipped_quantity') > 0).length > 0;
    if (hasAnyToShip) {
      // Check if there are any quantity items to be shipped.
      hasAnyToShip = this.collection
        .filter((model) => model.get('to_be_shipped_quantity') > 0)
        .length > 0;
    }
    this.ui.confirmButton.attr('disabled', !hasAnyToShip);
  },

  onShow() {
    this.handoutCheck();
  },

  serializeData() {
    const {
      shippingMethodCollection, mode, MODE_EDIT, MODE_CONFIRM,
    } = this;
    const shippingMethodModel = shippingMethodCollection.first();

    const hasMultipleMethods = shippingMethodCollection.length > 1;
    let shippingMethodIcon = null;
    if (!hasMultipleMethods) {
      shippingMethodIcon = ShipmentMethodType.getIconForType(shippingMethodModel.get('shipping_type.alias'));
    }

    return {
      shippingMethodId: this.shippingMethodId,
      methods: shippingMethodCollection.toJSON(),
      methodName: shippingMethodModel ? shippingMethodModel.get('name') : null,
      hasMultipleMethods,
      mode,
      MODE_EDIT,
      MODE_CONFIRM,
      number: this.model.get('number'),
      isEmpty: this.collection.length === 0,
      hasReceiptPrinter: ReceiptPrinter.isWantedType(),
      shippingMethodIcon,
    };
  },

}));
