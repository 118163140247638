define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/shippingModal/overview/collection',

  './collectionItem',
  './collectionStaticItem',
  'modules/shop.cash-register-retail/views/keypads/main',

  'modules/shop.cash-register-retail/models/keyboard',
], (
  $, _, Backbone, Template,
  CollectionItem, CollectionStaticItem, KeypadsView,
  KeyboardModel,
) => Backbone.Marionette.CompositeView.extend({

  template: Template,

  className: 'shipping-overview',

  getChildView() {
    if (this.mode === 'edit') {
      return CollectionItem;
    }

    return CollectionStaticItem;
  },

  childViewContainer: 'tbody',

  childViewOptions() {
    return {
      showSku: this.showSku,
    };
  },

  ui: {
    handoutAll: '[data-ui=handout-all]',
    keypad: '[data-ui=keypad]',
  },

  regions: {
    keypad: '[data-ui=keypad]',
  },

  events: {
    'click @ui.handoutAll': 'handoutAllClicked',
  },

  collectionEvents: {
    change: 'checkSelectAll',
  },

  initialize({ collection, mode, showSku = true }) {
    this.collection = collection;
    this.mode = mode;
    this.showSku = showSku;
  },

  handoutAllClicked() {
    const toBeShipped = !this.allToBeShipped();
    this.collection.each((model) => {
      // Enable all disabled items
      if (toBeShipped) {
        const { to_be_shipped_quantity, unfulfilled_quantity } = model.toJSON();
        if (to_be_shipped_quantity === 0) {
          model.set('to_be_shipped_quantity', unfulfilled_quantity);
        }
      } else {
        // Disable all items
        model.set('to_be_shipped_quantity', 0);
      }
    });
  },

  onRender() {
    if (this.mode === 'edit') {
      this.checkSelectAll();
      this.renderKeypad();
    }
  },

  renderKeypad() {
    const view = new KeypadsView();
    const region = new Backbone.Marionette.Region({
      el: this.$el.find(this.regions.keypad),
    });
    region.show(view);
  },

  checkSelectAll() {
    this.ui.handoutAll.get(0).checked = this.allToBeShipped();
  },

  allToBeShipped() {
    return this.collection
      .filter((model) => model.get('to_be_shipped_quantity') > 0)
      .length > 0;
  },

  onShow() {
    KeyboardModel.on('change:mode', this.toggleKeypad, this);
  },

  onDestroy() {
    KeyboardModel.off('change:mode', this.toggleKeypad, this);
  },

  toggleKeypad() {
    if (KeyboardModel.get('mode') === KeyboardModel.MODE_INTEGER) {
      this.ui.keypad.show();
    } else {
      this.ui.keypad.hide();
    }
  },

  serializeData() {
    const { mode } = this;
    return { mode };
  },

}));
