define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/successScreens/order/layout.hbs',

  'modules/shop.cash-register-retail/views/successScreens/order/summary',

  'modules/shop.cash-register-retail/views/payments/processingCollection',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
  'modules/shop.cash-register-retail/components/feature',
  'modules/shop.cash-register-retail/models/upx/LoyaltyProgram',

  'modules/admin/behaviors/loader',
  'modules/common/components/historyBreadcrumb',
  'modules/shop.cash-register-retail/components/printing',
  'modules/shop.cash-register-retail/views/popups/messagePopup',
  'modules/shop.cash-register-retail/components/loyaltyProgram',
], (
  $, _, Backbone, Template,
  SummaryView,
  ProcessingCollectionView, ReceiptPrinterModel, Feature, LoyaltyProgramModel,
  Loader, HistoryBreadcrumb, PrintingComponent, MessagePopup, LoyaltyProgramComponent,
) => Backbone.Marionette.LayoutView.extend({

  className: 'payment-success order-success-cashier',

  template: Template,

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  regions: {
    payments: '[data-region="payments"]',
  },

  events: {
    'click [data-action="back"]': 'backClicked',
    'click [data-action="receipt"]': 'receiptClicked',
  },

  initialize({
    model,
    customerModel,
    logCollection,
    hasCCVPayment = false,
    paymentMethodCollection,
    backRoute = null,
  }) {
    this.model = model;
    this.customerModel = customerModel;
    this.logCollection = logCollection;
    this.backRoute = backRoute;
    this.paymentMethodCollection = paymentMethodCollection;
    this.hasCCVPayment = hasCCVPayment;
  },

  backClicked() {
    if (this.backRoute) {
      HistoryBreadcrumb.goto(this.backRoute);
    } else {
      HistoryBreadcrumb.goBack();
    }
  },

  onRender() {
    let loyaltyProgramModel = null;

    if (Feature.isLoyaltyFeatureEnabled()) {
      loyaltyProgramModel = LoyaltyProgramModel;
    }

    this.getRegion('payments').show(new SummaryView({
      logCollection: this.logCollection,
      model: this.model,
      loyaltyProgramModel,
      selectedCustomer: this.customerModel,
    }));
  },

  receiptClicked() {
    const def = this.loader.startLoader('loader-print');

    const options = {
      // Because ccv will always print a receipt, this one is a reprint when there is a ccv payment
      is_reprint: this.hasCCVPayment,
    };

    const loyaltyPaymentData = LoyaltyProgramComponent.getLoyaltyPaymentData(
      this.model,
      this.paymentMethodCollection.getLoyaltyPointsPaymentModel(),
      this.loyaltyProgramModel,
      this.customerModel,
      !this.paymentMethodCollection.hasPayLaterPayments(),
    );

    if (loyaltyPaymentData !== null) {
      // Need this to print loyalty points on receipt
      loyaltyPaymentData.hasLoyaltyPoints = true;
      options.loyaltyPaymentData = loyaltyPaymentData;
    }

    PrintingComponent.printReceipt(this.model, options)
      .then(() => {
        this.backClicked();
        def.resolve();
      }, (response) => {
        if (response && 'error' in response) {
          def.reject();

          const view = new MessagePopup();
          view.open(
            response.error,
          );
        }
      });
  },

  serializeData() {
    const data = this.model.toJSON();
    data.has_receipt_printer = ReceiptPrinterModel.isWantedType();
    data.has_ccv_payment = this.hasCCVPayment;
    return data;
  },
}));
