define([
  'jquery',
  'underscore',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/tabs/swapPopup.hbs',

  'modules/shop.cash-register-retail/components/cashRegisterApi',

  'modules/shop.cash-register-retail/collections/tab',

  'magnific-popup',
], (
  $, _, PopupView, Template,
  CashRegisterApi,
  TabCollection,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--tab-swap',

  events: {
    'click [data-action="accept"]': 'acceptClicked',
    'click [data-action="close"]': 'closeClicked',
  },

  ui: {
    swapTo: '[data-ui=swap-to]',
  },

  acceptClicked: _.debounce(function () {
    const id = this.ui.swapTo.val();
    const toModel = TabCollection.get(id);
    if (toModel) {
      this.close();
      this.triggerMethod('tab:swap', this.model, toModel);
    }
  }, 50),

  open(def) {
    def = this.renderInFloatingRegion(def);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/tabs/list/infoPopup/popup',
    });

    $.magnificPopup.open({
      items: [{
        src: this.$el.get(0),
        type: 'inline',
      }],
      removalDelay: 0,
      mainClass: 'mfp-fade',
      modal: true,
    });
    return def;
  },

  getTabs() {
    const tabs = [];

    TabCollection.each((model) => {
      const data = model.toJSON();
      data.currencyIso3 = model.getCurrencyIso3();
      data.totals = model.getOrderTotals();
      tabs.push(data);
    });

    return tabs;
  },

  serializeData() {
    return {
      tabs: this.getTabs(),
      fromId: this.model.get('id'),
      fromTab: this.model.get('name'),
      currencyIso3: this.model.getCurrencyIso3(),
      totals: this.model.getOrderTotals(),
    };
  },

}));
