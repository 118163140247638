define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/tabs/listPopup/item.hbs',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
], (
  $, _, Backbone, Template,
  CurrentOrderItemCollection,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'tab designer-box rounded',

  events: {
    'click [data-action="info"]': 'infoClicked',
    'click [data-action="add-order-item"]': 'addClicked',
  },

  modelEvents: {
    change: 'render',
  },

  infoClicked() {
    this.triggerMethod('tab:info', this.model);
  },

  addClicked() {
    this.triggerMethod('tab:merge-order-items', this.model);
  },

  serializeData() {
    const data = this.model.toJSON();
    data.currency_iso3 = this.model.getCurrencyIso3();
    data.totals = this.model.getOrderTotals();
    data.addable = CurrentOrderItemCollection.length > 0;

    const lastItem = this.model.getOrderItemCollection().last();

    let lastAuthor = '';
    if (lastItem && lastItem.get('author_name')) {
      lastAuthor = lastItem.get('author_name');
    } else if (this.model.get('author_name')) {
      lastAuthor = this.model.get('author_name');
    }

    data.last_author_firstname = lastAuthor.split(' ')[0];
    return data;
  },

}));
