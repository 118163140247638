define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/tables/informationPopup/collectionItemRow.hbs',

  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
  'modules/shop.cash-register-retail/collections/loadable/productionGroups',

  'modules/common/components/currency',
  'modules/shop.common/components/commerceAuth',
], (
  $, _, Backbone, Template,
  ShopConfiguration, ProductionGroups,
  Currency, CommerceAuth,
) => Backbone.Marionette.LayoutView.extend({

  tagName: 'tr',

  template: Template,

  events: {
    'click [data-action=delete]': 'deleteClicked',
  },

  deleteClicked() {
    this.triggerMethod('row:delete', this.model);
  },

  serializeData() {
    const production_group_id = this.model.get('production_group_id');
    const productionGroup = ProductionGroups.get(production_group_id);
    const setOriginalPriceWt = (item) => {
      const extra = JSON.parse(item.extra || '{}');
      // Set original price
      if ('discount_data' in extra) {
        const { quantity } = item;
        const originalPpu = extra.discount_data.ppu_wt;
        item.original_price_wt = Currency.multiply(originalPpu, quantity);
      }
    };

    const data = this.model.toJSON();
    if (data.subitems) {
      for (let i = 0; i < data.subitems.length; i++) {
        const subItem = data.subitems[i];
        if (subItem.production_group_id
						&& subItem.production_group_id !== production_group_id
        ) {
          const productionGroup = ProductionGroups.get(subItem.production_group_id);
          subItem.production_group_name = productionGroup ? productionGroup.get('name') : false;
        }

        // Set original price
        setOriginalPriceWt(subItem);
      }
    }

    // Set original price
    setOriginalPriceWt(data);

    return _.extend({}, data, {
      can_delete: CommerceAuth.hasManagerAccess() || CommerceAuth.hasAdminAccess(),
      // The backend does the following
      is_deleted: data.deleted_quantity > 0 && data.quantity === 0,
      currency_iso3: ShopConfiguration.getCurrencyIso3(),
      production_group_name: productionGroup ? productionGroup.get('name') : false,
    });
  },

}));
