define([
  'jquery',
  'underscore',
  './item',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/templates/repairs/questions/canvasItem.hbs',

  'modules/upx/components/upx',
  'modules/common/components/blob',
  'modules/common/components/fileUploader',
  'modules/common/components/uuid',

  'signature_pad',
], (
  $, _, ItemView, Locale, Template,
  Upx, Blob, FileUploader, Uuid,
  SignaturePad,
) => ItemView.extend({

  template: Template,

  ui() {
    return _.extend({}, ItemView.prototype.ui, {
      signaturePad: '[name="answer"]',
    });
  },

  events: {
    'click [data-action="clear"]': 'clearClicked',
  },

  clearClicked() {
    this.signaturePad.clear();
    this.signaturePad.fromDataURL(this.backgroundImageObject.src, { ratio: 1 });
  },

  onShow() {
    this.signaturePad = new SignaturePad.default(this.ui.signaturePad.get(0));

    this.backgroundImageToDataURL(Upx.getResourceUrl(this.model.get('file.download_url')), (data) => {
      this.backgroundImageObject = new Image();
      this.backgroundImageObject.src = data;
      this.backgroundImageObject.onload = () => {
        const { width, height } = this.backgroundImageObject;
        this.ui.signaturePad.attr('width', width);
        this.ui.signaturePad.attr('height', height);
        this.signaturePad.fromDataURL(this.backgroundImageObject.src, { ratio: 1 });
      };
    });
  },

  backgroundImageToDataURL(url, callback) {
    const xhRequest = new XMLHttpRequest();
    xhRequest.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhRequest.response);
    };
    xhRequest.open('GET', url);
    xhRequest.responseType = 'blob';
    xhRequest.send();
  },

  hasAnswer() {
    return this.signaturePad._data.length > 0;
  },

  getValue() {
    const def = $.Deferred();
    const blob = Blob.fromBase64(this.signaturePad.toDataURL(), 'image/png');

    FileUploader.uploadBlob(blob, null, `${Uuid.genRandom()}.png`).then((url) => {
      const responseUrl = new URL(Upx.getResourceUrl(url));
      const image_file_id = responseUrl.searchParams.get('file_id');
      const value = {
        question_id: this.model.get('id'),
        question_text: this.model.get('question'),
        image_file_id,
      };
      def.resolve(value);
    }, (resp) => {
      def.reject(resp);
    });

    return def.promise();
  },
}));
