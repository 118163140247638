define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/success/summary',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',
  'modules/common/components/currency',
  'modules/shop.cash-register-retail/components/feature',
  'modules/shop.cash-register-retail/components/loyaltyProgram',

  'modules/common/collections/currencies',
  'modules/shop.cash-register-retail/models/selectedCustomer',

  'modules/shop.cash-register-retail/models/settings/paymentMethods',
], (
  $, _, Backbone, ItemView,
  Locale, PaymentMethods, Currency, Feature, LoyaltyProgramComponent,
  CurrenciesCollection, SelectedCustomer,
  PaymentMethodSettings,
) => Backbone.Marionette.LayoutView.extend({
  template: ItemView,

  className: 'summary',

  initialize({
    model,
    logCollection = new Backbone.Collection(),
    loyaltyProgramModel = null,
    selectedCustomer = null,
  }) {
    this.selectedCustomer = selectedCustomer;
    if (!selectedCustomer) {
      this.selectedCustomer = SelectedCustomer;
    }

    this.model = model;
    this.logCollection = logCollection;
    this.loyaltyProgramModel = loyaltyProgramModel;
    this.payments = this.getPaymentArray(logCollection);
  },

  isCashPayment(id) {
    return this.isRowType(id, 'payment|cash');
  },
  isPinPayment(id) {
    return this.isRowType(id, 'payment|pin');
  },
  isPayment(id) {
    return this.isRowType(id, 'payment|');
  },
  isGiftCardRefund(id) {
    return id.includes('|gift_card');
  },
  isRowType(id, type) {
    id = id || '';
    return id.startsWith(type);
  },

  getPaymentArray(logCollection) {
    const payments = [];
    logCollection.each((model) => {
      const id = model.get('id') || '';
      if (this.isCashPayment(id)) {
        if (model.has('cashRefunded')) {
          payments.push({
            type: 'cash-refunded',
            name: Locale.translate('refunded_by_cash'),
            value_wt: model.get('cashRefunded'),
          });
        }
        if (model.has('cashReceived')) {
          payments.push({
            type: 'cash-received',
            name: Locale.translate('received_by_cash'),
            value_wt: model.get('cashReceived'),
          });
        }
        if (model.has('changeReturned')) {
          payments.push({
            type: 'cash-returned',
            name: Locale.translate('return_in_cash_to_customer'),
            value_wt: model.get('changeReturned'),
            className: model.get('changeReturned') !== '0.00' ? 'has-change' : '',
          });
        }
      } else if (this.isPinPayment(id) && parseFloat(model.get('value')) < 0) {
        payments.push({
          type: PaymentMethods.PIN_RETURN_METHOD,
          name: PaymentMethods.getNameByMethod(PaymentMethods.PIN_RETURN_METHOD),
          value_wt: model.get('value'),
        });
      } else if (this.isGiftCardRefund(id) && parseFloat(model.get('value')) < 0) {
        payments.push({
          type: PaymentMethods.GIFTCARD_REFUND_METHOD,
          name: PaymentMethods.getNameByMethod(PaymentMethods.GIFTCARD_REFUND_METHOD),
          value_wt: parseFloat(model.get('value')) * -1,
          code: model.get('code'),
        });
      } else if (this.isPayment(id)) {
        const type = id.substring(8);
        payments.push({
          type: 'payment',
          name: PaymentMethods.getNameByMethod(type),
          value_wt: model.get('value'),
        });
      }
    });
    return payments;
  },

  getLoyaltyPaymentData() {
    let loyaltyProgramPointsPaymentModel = null;
    this.logCollection.each((model) => {
      if (model.has('id') && model.get('id') === 'payment|loyalty_points') {
        loyaltyProgramPointsPaymentModel = model;
      }
    });

    const knownPaylaterMethods = [
      PaymentMethodSettings.INVOICE_METHOD,
      PaymentMethodSettings.PAYLATER_METHOD,
    ];

    const payLaterMethods = _.filter(this.logCollection.toJSON(), (model) => {
      const { id = '', value = '0.00' } = model;
      if (id.substring(0, 8) === 'payment|') {
        const paymentType = id.substring(8);
        return knownPaylaterMethods.includes(paymentType)
                    && parseFloat(value) > 0;
      }

      return false;
    });

    return LoyaltyProgramComponent.getLoyaltyPaymentData(
      this.model,
      loyaltyProgramPointsPaymentModel,
      this.loyaltyProgramModel,
      this.selectedCustomer,
      payLaterMethods.length === 0,
    );
  },

  serializeData() {
    const iso3 = this.model.get('currency_iso3');
    const currencyObject = CurrenciesCollection.getCurrencyObjectUsingIso3(iso3);
    const currencyIsoSymbol = currencyObject.symbol;

    const data = $.extend({}, this.model.toJSON(), {
      payments: this.payments,
      isLoyaltyFeatureEnabled: Feature.isLoyaltyFeatureEnabled(),
      loyaltyPayment: this.getLoyaltyPaymentData(),
      value_wt_formatted: `${currencyIsoSymbol} ${
        Currency.formatAmount(iso3, this.model.get('value_wt'))}`,
    });

    if (data.success_handed_out_no >= 0) {
      data.item_no = data.success_handed_out_no;
    }

    return data;
  },

}));
